
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import {getAPI} from "../../services/apiService"
import { GET_WALLET_DETAILS } from "../../services/apiConstant"
import { ReturnStatus,OrderStatus } from './OrderEnum'

function BankDetailsPopup (props)  {
    const[walletResponse,setWalletResponse]=useState(null)


    useEffect(() => {
        fetchBankDetails()
        
      }, [])


      const fetchBankDetails = async ()=>{
       // console.log('props?.data',props?.data)
        const uid=props?.data?.userid
        const walletResponse = await getAPI(GET_WALLET_DETAILS +uid).catch(error=>console.log('walletResponse error',error));
        if(walletResponse && !walletResponse.error){
            setWalletResponse(walletResponse)
        }
      }
    
    return (<>
        <Modal show={true} onHide={props.onClosePopup} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> {"Refund Details"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
          <div className='wrapper-user' key={"user"}>
          {walletResponse&& <div style={{fontSize:22,margin:15,marginTop:0,fontWeight:'600'}}> Bank Details</div>}

        {walletResponse ?  <div className='row-home'>

            <div className='column-home ml-20'>
              <div className='prd-name'>{}</div>
              <div><span>Full Name:</span> {walletResponse?.fullName}</div>
              <div><span>Mobile Number:</span> {walletResponse?.mobileNumber}</div>
              <div><span>Bank Name:</span> {walletResponse?.bankName}</div>
              <div><span>Bank Accoun Number:</span> {walletResponse?.bankAccountNumber}</div>
              <div><span>Bank IFSC:</span> {walletResponse?.ifscCode}</div>

            </div>
          </div>:
          <div style={{textAlign:'center',fontSize:18}}>
            Bank details not added
          </div>
          }
        {walletResponse && props?.data?.returnStatus===ReturnStatus.RETURN_COMPLETED  && props?.data?.paymentInfo.paymentStatus === 'success'
         && <Button variant="primary" onClick={()=>props.sendForRefund(props.data,walletResponse)} style={{ marginRight: 10 ,alignSelf:'flex-end',width:'200px'}}> Add to Refund List</Button>}
        {walletResponse && props?.data?.returnStatus===ReturnStatus.REFUND_SEND && <div style={{ alignSelf:'flex-end',width:'200px',fontSize:18,fontWeight:'600',color:'green'}}> Added to Refund List</div>}

        </div>  
            </Modal.Body>
            <Modal.Footer>
                <div className='flex-row justify'>
                    <div className='flex-row' style={{display:'contents'}}>
                        <Button variant="secondary" onClick={props.onClosePopup} style={{ marginRight: 10 }}>
                            Close
                        </Button>
                        <div style={{alignSelf:'center',fontWeight:'600'}}><span>Total Refund Amount:</span> Rs. {props.data?.paymentInfo?.totalPayment}</div>

                       
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>)



}

export default BankDetailsPopup